import { FC } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import clsx from 'clsx'
import ReactMarkdown from 'react-markdown'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Label, Typography } from '@sylveraio/design-system'
import { formatDate, sendAnalyticsEvent } from '@sylveraio/react-utils'
import { PolicyArticleSylverasTake } from './PolicyArticleSylverasTake'
import type { PolicyArticleProps } from './types'
import LinkExternal02 from '@sylveraio/untitled-ui-icons-react/build/esm/LinkExternal02'

export const PolicyArticle: FC<PolicyArticleProps> = ({
  testId = 'policy-article',
  img,
  title,
  summary,
  date,
  externalLink,
  sylverasTake,
  pills,
  mode = 'full',
}) => {
  const full = mode === 'full'
  const preview = mode === 'preview'
  const headline = mode === 'headline'

  return (
    <article
      data-testid={testId}
      className={clsx('flex flex-col gap-y-2 overflow-hidden group', {
        'w-[587px]': full,
        'w-[258px] h-[221px] bg-high p-2 rounded-lg border border-subtle hover:bg-medium':
          preview,
        'w-full h-fit p-2	hover:bg-low rounded-lg': headline,
      })}
    >
      {img && (
        <div
          data-testid={`${testId}-thumbnail`}
          className={clsx('!w-full rounded overflow-hidden relative shrink-0', {
            'h-[94px]': full,
            'h-[55px]': preview,
          })}
        >
          <Image
            src={img.src}
            height={img.height}
            width={img.width}
            alt={img.alt || title}
            className={clsx('!w-full object-cover object-center', {
              'h-[94px]': full,
              'h-[55px] group-hover:scale-110 transition duration-500': preview,
            })}
            unoptimized
          />
        </div>
      )}
      <section className="flex flex-col gap-y-1">
        <div
          data-testid={`${testId}-meta`}
          className="flex items-center gap-x-1"
        >
          <time
            data-testid={`${testId}-date`}
            dateTime={date}
            className="text-xs font-medium"
          >
            {formatDate(date, 'd MMM yyyy')}
          </time>
          {pills?.map((pill) => {
            return (
              <Label
                key={`${testId}-filters`}
                testId={`${testId}-filters`}
                label={pill.name}
                color="neutral"
                size="sm"
              />
            )
          })}
        </div>
        <h2
          data-testid={`${testId}-title`}
          className={clsx('font-semibold', {
            'line-clamp-2': preview,
            'text-sm': !full,
            'text-lg': full,
          })}
        >
          {title}
        </h2>
        <section
          data-testid={`${testId}-summary`}
          className={clsx('font-normal', {
            shrink: preview,
            'text-xs': !full,
            'text-sm': full,
          })}
        >
          {preview && sylverasTake ? (
            <PolicyArticleSylverasTake
              sylverasTake={
                typeof sylverasTake === 'string'
                  ? sylverasTake
                  : sylverasTake.json
              }
              clampLines
            />
          ) : (
            summary
          )}
        </section>
        {full && typeof externalLink !== 'undefined' && (
          <div className="mt-2 flex items-center gap-x-2">
            {typeof externalLink === 'string' ? (
              <Link
                href={externalLink}
                target="_blank"
                className="flex gap-x-1 text-sm font-semibold text-content-action-default"
                onClick={() =>
                  sendAnalyticsEvent({
                    eventName: 'market-commentary-go-to-link',
                    eventData: {
                      externalLink,
                    },
                  })
                }
              >
                <span>Read article</span>
                <LinkExternal02 className="w-4 h-4 pl-1 content-action-default" />
              </Link>
            ) : Array.isArray(externalLink) && externalLink.length > 0 ? (
              externalLink.map((link) => (
                <Link
                  key={link.url}
                  href={link.url}
                  target="_blank"
                  className="flex items-center text-content-action-default"
                  onClick={() =>
                    sendAnalyticsEvent({
                      eventName: 'market-commentary-go-to-link',
                      eventData: {
                        externalLink,
                      },
                    })
                  }
                >
                  <Typography
                    size="sm"
                    weight="semibold"
                    className="!text-content-action-default"
                  >
                    {link.displayText}
                  </Typography>
                  <LinkExternal02 className="w-4 h-4 pl-1 content-action-default" />
                </Link>
              ))
            ) : null}
          </div>
        )}
      </section>
      {full && sylverasTake && (
        <section className="rounded bg-low p-4 font-maison">
          <h2 className="font-medium text-default">Sylvera&apos;s take</h2>
          {typeof sylverasTake === 'string' ? (
            <ReactMarkdown className="text-sm">{sylverasTake}</ReactMarkdown>
          ) : (
            documentToReactComponents(sylverasTake.json)
          )}
        </section>
      )}
    </article>
  )
}
