import { ProvisionalRating } from 'types'
import type { SylveraRatingsSizes } from './types'

export const RING_SIZES: {
  outer?: {
    [x in SylveraRatingsSizes]: string
  }
  middle?: {
    [x in SylveraRatingsSizes]: string
  }
  inner?: {
    [x in SylveraRatingsSizes]: string
  }
} = {
  outer: {
    sm: 'w-[48px] h-[48px]',
    md: 'w-[52px] h-[52px]',
    lg: 'w-[64px] h-[64px]',
  },
  middle: {
    sm: 'w-[42px] h-[42px]',
    md: 'w-[46px] h-[46px]',
    lg: 'w-[56px] h-[56px]',
  },
  inner: {
    sm: 'w-[38px] h-[38px]',
    md: 'w-[42px] h-[42px]',
    lg: 'w-[52px] h-[52px]',
  },
}

export const TEXT_SIZES: {
  [x in SylveraRatingsSizes]: string
} = {
  sm: 'text-sm',
  md: 'text-sm',
  lg: 'text-base',
}

export const BADGE_LOCATIONS: {
  [x in SylveraRatingsSizes]: string
} = {
  sm: 'top-0.5 right-0.5',
  md: 'top-0.5 right-0.5',
  lg: 'top-1 right-1',
}

export const PROVISIONAL_RATING_SHORTNAMES: {
  [x in ProvisionalRating]: string
} = {
  positive: 'P+',
  neutral: 'P',
  negative: 'P-',
}
