'use client'

import { Carousel } from '@sylveraio/react-ui'
import { FC } from 'react'
import type { PolicyArticleCarouselProps } from './types'
import { sendAnalyticsEvent } from '@sylveraio/react-utils'
import { NewsCard } from '@sylveraio/design-system'
import { useTransformArticles } from '../../../utils/hooks/useTransformArticles'
import Link from 'next/link'

export const PolicyArticleCarousel: FC<PolicyArticleCarouselProps> = ({
  articles = [],
  selectedFilters,
}) => {
  const transformedArticles = useTransformArticles(articles)

  if (!transformedArticles) return null

  const handleNextClick = () => {
    sendAnalyticsEvent({
      eventName: 'market-commentary-page-for-you-pagination-next',
    })
  }

  const handlePrevClick = () => {
    sendAnalyticsEvent({
      eventName: 'market-commentary-page-for-you-pagination-prev',
    })
  }

  return (
    <Carousel
      onNextClick={handleNextClick}
      onPrevClick={handlePrevClick}
      content={transformedArticles.map((transformedArticle) => (
        <Link
          key={transformedArticle.id}
          href={transformedArticle.linkTo}
          onClick={() => {
            sendAnalyticsEvent({
              eventName:
                'market-commentary-page-trending-articles-article-open',
              eventData: {
                articleId: transformedArticle.id,
                articleTitle: transformedArticle.title,
                articleTags: transformedArticle.tags,
                customisedFeedFilters: selectedFilters,
              },
            })
          }}
        >
          <NewsCard
            key={transformedArticle.id}
            img={transformedArticle?.img}
            date={transformedArticle.date}
            tags={transformedArticle?.tags}
            title={transformedArticle.title}
            summaryHeading="Sylvera's take"
            summary={transformedArticle.summary}
            className="mr-4"
          />
        </Link>
      ))}
      enhanceSlickTrack
    />
  )
}
