import type { Stage, UserDetails } from 'types'

export const shouldLockRatings = (
  projectStage: Stage,
  hasRatingsAccess: boolean,
  projectId?: string,
  userSubscription?: UserDetails['subscription'],
  userFeatureAccess?: UserDetails['featureAccess'],
) => {
  return (
    // The project ID is not provided
    !projectId ||
    // The user does not have access to the Ratings app
    !hasRatingsAccess ||
    // The user is not subscribed to the project and is not an Enterprise or Business+ user
    (!userSubscription?.projects.includes(projectId) &&
      !userFeatureAccess?.canViewEnhancedUnsubscribed) ||
    // The project is pre-issuance and the user does not have pre-issuance access
    (projectStage === 'Pre-issuance' &&
      !userFeatureAccess?.canViewPreIssuanceProjects)
  )
}
