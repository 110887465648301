import clsx from 'clsx'
import { type ChangeEvent, type FC, useEffect, useRef, useState } from 'react'
import { SearchableDropdownList } from '@sylveraio/react-ui'
import { useClickOutsideElement } from '@sylveraio/react-utils'
import type { PolicyArticleDropdownFilterProps } from './types'

export const PolicyArticleDropdownFilter: FC<
  PolicyArticleDropdownFilterProps
> = ({
  testId = 'policy-article-dropdown-filter',
  title,
  name,
  handleChange,
  selectedFilters,
  filterValues,
  className,
  hideSearch,
  hideLabel,
  isMultiSelect = true,
  size = 'md',
}) => {
  const filterRef = useRef<HTMLDivElement>(null)
  const [search, setSearch] = useState<string | undefined>('')
  const [searchFilterSet, setSetSearchFilterSet] = useState(filterValues || [])

  const [visibleDropdown, setVisibleDropdown] = useState<boolean>(false)

  useClickOutsideElement(
    filterRef,
    () => {
      setVisibleDropdown(false)
    },
    visibleDropdown,
  )

  const handleDropdownClick = () => {
    setVisibleDropdown(!visibleDropdown)
  }

  const handleFilterItemSelect = (ev: ChangeEvent<HTMLInputElement>) => {
    handleChange(ev)
  }

  const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setSearch(target?.value)
  }

  useEffect(() => {
    if (filterValues) {
      const searchRegex = new RegExp(`${search}`, 'i')
      setSetSearchFilterSet(
        filterValues.filter(({ value }) => value.match(searchRegex)),
      )
    }
  }, [search])

  return (
    <div ref={filterRef} className="relative px-1">
      <SearchableDropdownList
        testId={testId}
        name={name}
        onOpen={handleDropdownClick}
        isOpen={visibleDropdown}
        onChange={handleFilterItemSelect}
        items={searchFilterSet}
        isMultiSelect={isMultiSelect}
        selected={selectedFilters || []}
        onSearchChange={hideSearch ? undefined : handleSearch}
        placeholder="Search"
        searchValue={search}
        title={title}
        onReset={() => setSearch('')}
        wrapperClassName="w-full"
        className={clsx('!bg-transparent justify-between', className)}
        modalClassName="absolute top-full right-0 z-50"
        size={size}
        label={
          !hideLabel && selectedFilters && selectedFilters.length
            ? `+${selectedFilters.length}`
            : undefined
        }
      />
    </div>
  )
}
