import { Modal, PillGroupProps } from '@sylveraio/react-ui'
import { FC, useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { PolicyArticle } from '../../components/PolicyArticle'
import QueryString from 'qs'
import { useArticle } from '../../utils/hooks/useArticle'

type MarketCommentaryArticleProps = {
  testId?: string
  id: string
}

export const MarketCommentaryArticle: FC<MarketCommentaryArticleProps> = ({
  testId = 'market-commentary-article',
  id,
}) => {
  const { push } = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const { article: transformedContentfulArticle, tags: contentfulArticleTags } =
    useArticle(id)

  const getPreviousParams = (): string => {
    if (!searchParams) return ''
    const params: Record<string, unknown> = {}
    searchParams.forEach((v, k) => {
      if (k !== 'policyArticle') {
        params[k] = v
      }
    })
    if (!isEmpty(params)) return `?${QueryString.stringify(params)}`
    return ''
  }

  const pills: PillGroupProps['pills'] | undefined = useMemo(() => {
    return contentfulArticleTags
  }, [contentfulArticleTags])

  const handleClose = () =>
    pathname && push(`${pathname}${getPreviousParams()}`)

  const article = useMemo(
    () => transformedContentfulArticle,
    [transformedContentfulArticle],
  )

  if (!article) return null

  return (
    <Modal
      showCloseButton
      withOverlay
      handleClose={handleClose}
      testId={testId}
      className="max-h-screen overflow-y-auto rounded-lg"
    >
      <PolicyArticle {...article} pills={pills} />
    </Modal>
  )
}
