import { ButtonLegacy, Typography } from '@sylveraio/design-system'
import { type ChangeEvent, type FC, useEffect, useState } from 'react'
import isEqual from 'lodash/isEqual'
import type { CustomiseModalProps } from './types'
import { PolicyArticleDropdownFilter } from '../PolicyArticleDropdownFilter'
import { toggleArrayValue } from '@sylveraio/react-utils'
import type { SelectedPolicyFilters } from '@sylveraio/data'
import clsx from 'clsx'
import { isPolicyFiltersEmpty } from '../../../utils/isPolicyFiltersEmpty'
import { CUSTOMIZE_YOUR_FEED_LABEL } from './constants'

const DEFAULT_CHANGESET = {
  policyTags: [],
  countries: [],
  projectTypes: [],
}

export const CustomiseModal: FC<CustomiseModalProps> = ({
  selectedFilters = {},
  onSave,
  isOpen,
  filterValues,
}) => {
  const [changeSet, setChangeSet] =
    useState<SelectedPolicyFilters>(selectedFilters)

  const handleChangeSetUpdate = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setChangeSet((prevChangeSet) => ({
      ...prevChangeSet,
      [target.name]: toggleArrayValue(
        prevChangeSet?.[target.name as keyof SelectedPolicyFilters] || [],
        target.value,
      ),
    }))
  }

  const handleClear = () => setChangeSet(DEFAULT_CHANGESET)

  const handleSave = (changeSet: SelectedPolicyFilters | undefined) => {
    onSave(changeSet)
  }

  useEffect(() => {
    if (isPolicyFiltersEmpty(selectedFilters)) return
    setChangeSet(selectedFilters)
  }, [selectedFilters])
  return (
    <div
      className={clsx(
        'flex flex-col rounded absolute right-0 top-full bg-high p-2 z-[100] shadow-md  border border-default gap-y-2',
        {
          invisible: !isOpen,
        },
      )}
    >
      <div className="flex flex-col gap-y-2">
        <section className="p-2 text-left">
          <Typography
            size="sm"
            weight="semibold"
            className="inline text-nowrap"
          >
            {CUSTOMIZE_YOUR_FEED_LABEL}
          </Typography>
        </section>

        {typeof filterValues !== 'undefined' && (
          <section className="flex flex-col gap-y-2 px-2 py-1">
            <PolicyArticleDropdownFilter
              testId="policy-article-tags-dropdown-filter"
              title="Category"
              name="policyTags"
              handleChange={handleChangeSetUpdate}
              selectedFilters={changeSet?.policyTags}
              filterValues={filterValues?.policyTags}
            />
            <PolicyArticleDropdownFilter
              testId="policy-article-country-dropdown-filter"
              title="Country"
              name="countries"
              handleChange={handleChangeSetUpdate}
              selectedFilters={changeSet?.countries}
              filterValues={filterValues?.countries}
            />
            <PolicyArticleDropdownFilter
              testId="policy-article-project-type-dropdown-filter"
              title="Project Type"
              name="projectTypes"
              handleChange={handleChangeSetUpdate}
              selectedFilters={changeSet?.projectTypes}
              filterValues={filterValues?.projectTypes}
            />
          </section>
        )}
      </div>
      <div className="mx-2 h-0 border-t border-default" />
      <div className="flex items-center gap-x-2 px-2 py-1">
        <ButtonLegacy
          className="flex-1 !px-0"
          appearance="solid"
          theme="white"
          onClick={handleClear}
          text="Clear all"
          disabled={isPolicyFiltersEmpty(changeSet)}
        />
        <ButtonLegacy
          className="flex-1 !px-0"
          appearance="solid"
          theme="neon-blue"
          onClick={() => handleSave(changeSet)}
          disabled={isEqual(selectedFilters, changeSet)}
          text="Save"
        />
      </div>
    </div>
  )
}
