import { TileFilters } from '../../../components/TileFilters'
import {
  type ChangeEvent,
  type FC,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react'
import type { PolicyArticleAllArticlesProps } from './types'
import { PolicyArticleDropdownFilter } from '../PolicyArticleDropdownFilter'
import {
  Breakpoint,
  sendAnalyticsEvent,
  toggleArrayValue,
  useWindowDimensions,
} from '@sylveraio/react-utils'
import { usePaginatedFilteredArticles } from '../../../utils/hooks/usePaginatedFilteredArticles'
import { useTransformArticles } from '../../../utils/hooks/useTransformArticles'
import { EmptyState, NewsListItem, Search } from '@sylveraio/design-system'
import { Pagination, type RowCount } from '@sylveraio/design-system'
import { ALL_ARTICLES_LABEL } from '../../../constants'
import Link from 'next/link'
import clsx from 'clsx'
import { debounce } from 'lodash'
import Settings04 from '@sylveraio/untitled-ui-icons-react/build/esm/Settings04'

export const PolicyArticleAllArticles: FC<PolicyArticleAllArticlesProps> = ({
  testId = 'all-policy-articles',
  filterValues,
}) => {
  const sectionRef = useRef<HTMLDivElement>(null)
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState<RowCount>(10)
  const [selectedTag, setSelectedTag] = useState(ALL_ARTICLES_LABEL)
  const [selectedCountries, setSelectedCountries] = useState<Array<string>>([])
  const [selectedProjectTypes, setSelectedProjectTypes] = useState<
    Array<string>
  >([])
  const [searchQuery, setSearchQuery] = useState('')
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('')

  const { isBelowScreenWidth } = useWindowDimensions()
  const isBelowDesktopMedium = isBelowScreenWidth(Breakpoint.DesktopMedium)

  const selectedFilters = {
    policyTags: selectedTag !== ALL_ARTICLES_LABEL ? [selectedTag] : [],
    countries: selectedCountries,
    projectTypes: selectedProjectTypes,
  }

  const { filteredArticles, filteredTotalArticles } =
    usePaginatedFilteredArticles(
      searchQuery === '' ? searchQuery : debouncedSearchQuery,
      selectedFilters,
      rowsPerPage,
      page,
    )

  const totalPages = Math.ceil((filteredTotalArticles || 0) / rowsPerPage)

  const policyArticles = useTransformArticles(filteredArticles)
  const hasPolicyArticles = policyArticles?.length !== 0

  const handleSelectedTag = (filter: string) => {
    setPage(1)
    setSelectedTag(filter)
  }

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setPage(1)

    const { name, value } = target
    switch (name) {
      case 'countries': {
        const countries = toggleArrayValue(selectedCountries, value)

        sendAnalyticsEvent({
          eventName: 'market-commentary-page-all-articles-filters-update',
          eventData: {
            allArticlesFilters: {
              ...selectedFilters,
              countries,
            },
          },
        })

        setSelectedCountries(countries)
        break
      }
      case 'projectTypes': {
        const projectTypes = toggleArrayValue(selectedProjectTypes, value)

        sendAnalyticsEvent({
          eventName: 'market-commentary-page-all-articles-filters-update',
          eventData: {
            allArticlesFilters: {
              ...selectedFilters,
              projectTypes,
            },
          },
        })
        setSelectedProjectTypes(projectTypes)
        break
      }
      case 'allArticles':
        setSelectedTag(() => {
          sendAnalyticsEvent({
            eventName: 'market-commentary-page-all-articles-filters-update',
            eventData: {
              allArticlesFilters: { ...selectedFilters, policyTags: value },
            },
          })

          return value
        })
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (debouncedSearchQuery !== '')
      sendAnalyticsEvent({
        eventName: 'market-commentary-page-all-articles-search',
        eventData: {
          searchQuery: debouncedSearchQuery,
        },
      })
  }, [debouncedSearchQuery])

  const scrollToSectionTop = () => {
    const rect = sectionRef.current?.getBoundingClientRect()
    if (rect) {
      const y = rect.top + window.scrollY - 10
      window.scrollTo({ top: y })
    }
  }

  const handlePageChange = (newPage: number) => {
    if (typeof newPage === 'undefined') return

    setPage(newPage)
    scrollToSectionTop()
  }

  const handleRowCountChange = (rowCount: RowCount) => {
    setRowsPerPage(rowCount)
    setPage(1)
  }

  const handleClearFilters = () => {
    setSelectedTag(ALL_ARTICLES_LABEL)
    setSelectedCountries([])
    setSelectedProjectTypes([])
  }

  const handleResetSearch = () => {
    debouncedSetSearchQuery('')
    setSearchQuery('')
  }

  const debouncedSetSearchQuery = useMemo(
    () =>
      debounce((value: string) => {
        setDebouncedSearchQuery(value)
      }, 600),
    [],
  )

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSearchQuery(value)
    debouncedSetSearchQuery(value)
  }

  const handlePaginationChange = (eventName: string, page: number) => {
    handlePageChange(page)
    sendAnalyticsEvent({
      eventName: eventName,
      eventData: {
        page: page,
      },
    })
  }

  return (
    <section ref={sectionRef} className="flex flex-col gap-y-2">
      <div className="flex items-center justify-between">
        {isBelowDesktopMedium ? (
          <PolicyArticleDropdownFilter
            testId={`${testId}-filters`}
            title={
              filterValues.policyTags?.find((tag) => tag.id === selectedTag)
                ?.value || ALL_ARTICLES_LABEL
            }
            name="allArticles"
            handleChange={handleChange}
            selectedFilters={[selectedTag]}
            filterValues={[
              { id: ALL_ARTICLES_LABEL, value: ALL_ARTICLES_LABEL },
              ...(filterValues.policyTags ?? []),
            ]}
            className="left-0 w-[150px]"
            hideSearch
            hideLabel
            isMultiSelect={false}
            size="lg"
          />
        ) : (
          <TileFilters
            filters={[ALL_ARTICLES_LABEL, ...(filterValues.policyTags ?? [])]}
            selected={selectedTag}
            onFilter={handleSelectedTag}
            testId={`${testId}-filters`}
            filtersClassName="!py-0"
            filterClassName="font-medium"
            ignoreDisabled={true}
          />
        )}
        <div className="flex items-center gap-x-2">
          <Search
            placeholder="Search articles"
            value={searchQuery}
            onChange={handleSearchChange}
            onReset={handleResetSearch}
          />
          <PolicyArticleDropdownFilter
            testId="policy-article-country-dropdown-filter"
            title="Country"
            name="countries"
            handleChange={handleChange}
            selectedFilters={selectedCountries}
            filterValues={filterValues?.countries}
          />
          <PolicyArticleDropdownFilter
            testId="policy-article-project-type-dropdown-filter"
            title="Project Type"
            name="projectTypes"
            handleChange={handleChange}
            selectedFilters={selectedProjectTypes}
            filterValues={filterValues?.projectTypes}
          />
        </div>
      </div>

      <div
        data-testid={`${testId}-content`}
        className={clsx('w-full grow gap-x-6 gap-y-4', {
          'grid items-end tablet:grid-cols-1 desktop-sm:grid-cols-2 gap-x-6 gap-y-4':
            hasPolicyArticles,
        })}
      >
        {!hasPolicyArticles ? (
          <EmptyState
            icon={<Settings04 className="w-6 h-6" />}
            title={
              searchQuery !== ''
                ? `No articles found including "${searchQuery}"`
                : 'No articles found'
            }
            subtitle={
              searchQuery !== ''
                ? 'Try adjusting your search term'
                : 'Adjust your feed filters to see more articles'
            }
            functionalActionName={
              searchQuery !== '' ? 'Clear Search' : 'Clear Filters'
            }
            functionalActionOnClick={
              searchQuery !== '' ? handleResetSearch : handleClearFilters
            }
            className="h-72"
          />
        ) : (
          policyArticles?.map((policy) => (
            <Link
              key={policy.id}
              href={policy.linkTo}
              onClick={() => {
                sendAnalyticsEvent({
                  eventName: 'market-commentary-page-all-articles-open',
                  eventData: {
                    articleId: policy.id,
                    articleTitle: policy.title,
                    articleTags: policy.tags,
                    customisedFeedFilters: selectedFilters,
                  },
                })
              }}
            >
              <NewsListItem
                key={policy.id}
                img={policy?.img}
                date={policy.date}
                tags={policy?.tags}
                title={policy.title}
                summaryHeading="Sylvera's take"
                summary={policy.summary}
              />
            </Link>
          ))
        )}
      </div>

      <section className="mx-2">
        {hasPolicyArticles && (
          <Pagination
            wrapperClassName="!mx-0"
            currentPage={page}
            totalPages={totalPages}
            onNextPage={() =>
              handlePaginationChange(
                'market-commentary-page-all-articles-pagination-next',
                page + 1,
              )
            }
            onPrevPage={() => handlePageChange(page - 1)}
            onFirstPage={() =>
              handlePaginationChange(
                'market-commentary-page-all-articles-pagination-first',
                1,
              )
            }
            onLastPage={() =>
              handlePaginationChange(
                'market-commentary-page-all-articles-pagination-last',
                totalPages,
              )
            }
            nextPageDisabled={page === totalPages}
            prevPageDisabled={page === 1}
            showPageCount
            showPrevNextButtons
            showFirstLastButtons
            showRowsDropdown
            rowsPerPage={rowsPerPage}
            onChangeRowCount={handleRowCountChange}
            customRowCount={[10, 30, 50]}
            rowCountLabel="Items per page:"
          />
        )}
      </section>
    </section>
  )
}
