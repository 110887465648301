import { FC, useRef, useState } from 'react'
import { PolicyArticleCarousel } from '../Carousel'
import { EmptyState, Typography } from '@sylveraio/design-system'
import type { PolicyArticleLatestNewsProps } from './types'
import type { SelectedPolicyFilters } from '@sylveraio/data'
import { CustomiseModalButton, CustomiseModal } from '../CustomiseModal'
import {
  sendAnalyticsEvent,
  useClickOutsideElement,
} from '@sylveraio/react-utils'
import { isPolicyFiltersEmpty } from '../../../utils/isPolicyFiltersEmpty'
import { useFilteredArticles } from '../../../utils/hooks/useFilteredArticles'
import Settings04 from '@sylveraio/untitled-ui-icons-react/build/esm/Settings04'

export const PolicyArticleLatestNews: FC<PolicyArticleLatestNewsProps> = ({
  defaultContent = [],
  onFilterChange,
  selectedFilters,
  filterValues,
}) => {
  const filterRef = useRef<HTMLDivElement>(null)
  const [showFilters, setShowFilters] = useState(false)

  const handleConfig = () => {
    setShowFilters((filter) => !filter)

    sendAnalyticsEvent({
      eventName:
        'market-commentary-page-trending-articles-customise-filters-open',
    })
  }

  const handleClose = () => {
    setShowFilters(false)
  }

  const handleFilterChange = (
    policyFilters?: SelectedPolicyFilters | undefined,
  ) => {
    handleClose()

    if (typeof policyFilters === 'undefined') return

    onFilterChange(policyFilters)

    if (!isPolicyFiltersEmpty(policyFilters)) {
      sendAnalyticsEvent({
        eventName:
          'market-commentary-page-trending-articles-customise-filters-update',
        eventData: {
          customisedFeedFilters: policyFilters,
        },
      })
    }
  }

  const handleEmptyStateClick = () => {
    setShowFilters(true)
  }

  useClickOutsideElement(filterRef, handleClose, showFilters, true)

  const articles = useFilteredArticles(selectedFilters)

  return (
    <section>
      <div className="flex justify-between">
        <div className="flex items-center space-x-2">
          <Typography size="lg" weight="semibold">
            For you
          </Typography>
        </div>
        <div ref={filterRef}>
          <CustomiseModalButton onClick={handleConfig} />
          <div className="relative">
            <CustomiseModal
              selectedFilters={selectedFilters || ([] as SelectedPolicyFilters)}
              onSave={handleFilterChange}
              isOpen={showFilters}
              filterValues={filterValues}
            />
          </div>
        </div>
      </div>
      {articles.length === 0 ? (
        <EmptyState
          icon={<Settings04 className="w-6 h-6" />}
          title="No articles found"
          subtitle="Adjust your filters to see more articles"
          functionalActionName="Customize your feed"
          functionalActionOnClick={handleEmptyStateClick}
          className="h-72"
        />
      ) : (
        <div className="relative py-4">
          <PolicyArticleCarousel
            articles={articles}
            selectedFilters={selectedFilters}
          />
          <div className="absolute right-0 top-0 h-full w-16 bg-gradient-to-l from-default from-15%" />
        </div>
      )}
    </section>
  )
}
