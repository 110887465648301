'use client'
import type { FC } from 'react'
import { useState, useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'
import {
  useUser,
  useRatings,
  type TransformedRatedProjects,
} from '@sylveraio/data'
import {
  RATINGS_TILE_DATA_SORT,
  RATINGS_TILE_DATA_PAGE_SIZE,
  RATINGS_TILE_DATA_PUBLIC_FIELDS,
  RATINGS_TILE_DATA_PRIVATE_FIELDS,
  LOADING_STATE_MOCK_RATINGS_DATA,
  UI_STRINGS,
} from './constants'
import { RatingsTable } from '../../components/RatingsTable/RatingsTable'
import { useRatingsTableColumns } from '../../utils/hooks/useRatingsTableColumns'
import type { Stage } from 'types'
import { shouldLockRatings } from './utils/shouldLockRatings'
import type { RatingsTablePermissionedProps } from './types'
import isEqual from 'lodash/isEqual'
import { EmptyState } from '@sylveraio/design-system'
import Ratings from '@sylveraio/untitled-ui-icons-react/build/esm/Ratings'
import { getAppUrl } from '@sylveraio/react-utils'

export const RatingsTablePermissioned: FC<RatingsTablePermissionedProps> = ({
  filterRecord,
  hideColumns,
  showEmptyState,
  disableRequestProject,
}) => {
  const { hasAccess, data } = useUser()

  const hasRatingsAccess = hasAccess('ratings')
  const [tableData, setTableData] = useState<TransformedRatedProjects>([])

  const { data: publicRatingsData, isFetching: isFetchingPublicRatingsData } =
    useRatings(
      false,
      RATINGS_TILE_DATA_PAGE_SIZE,
      RATINGS_TILE_DATA_SORT,
      filterRecord,
      RATINGS_TILE_DATA_PUBLIC_FIELDS.join(','),
    )

  const { data: fullRatingsData, isFetching: isFetchingFullRatingsData } =
    useRatings(
      true,
      RATINGS_TILE_DATA_PAGE_SIZE,
      RATINGS_TILE_DATA_SORT,
      filterRecord,
      [
        ...RATINGS_TILE_DATA_PUBLIC_FIELDS,
        ...RATINGS_TILE_DATA_PRIVATE_FIELDS,
      ].join(','),
      publicRatingsData,
    )

  useEffect(() => {
    if (typeof fullRatingsData !== 'undefined' && !isEmpty(fullRatingsData)) {
      // Use each rating from fullRatingsData if available, otherwise use the corresponding rating from publicRatingsData
      setTableData(
        publicRatingsData?.map((publicRating) => {
          const fullRating = fullRatingsData?.find(
            (fullRating) => fullRating.projectId === publicRating.projectId,
          )

          return fullRating ? { ...publicRating, ...fullRating } : publicRating
        }) ?? [],
      )
    } else if (
      typeof publicRatingsData !== 'undefined' &&
      !isEmpty(publicRatingsData)
    ) {
      setTableData(publicRatingsData)
    } else if (isFetchingPublicRatingsData) {
      setTableData(LOADING_STATE_MOCK_RATINGS_DATA)
    }
    return
  }, [publicRatingsData, fullRatingsData])

  const formattedColumns = useRatingsTableColumns(
    hasRatingsAccess,
    isFetchingPublicRatingsData,
    isFetchingFullRatingsData || publicRatingsData === fullRatingsData,
    hideColumns,
    disableRequestProject,
  )

  const handleShouldLockRatings = (projectStage: Stage, projectId?: string) => {
    return shouldLockRatings(
      projectStage,
      hasRatingsAccess,
      projectId,
      data?.subscription,
      data?.featureAccess,
    )
  }
  const handleOnProjectCatalogClick = () => {
    window.open(`${getAppUrl('ratings')}`, '_blank')
  }
  return (
    <>
      {!isFetchingFullRatingsData &&
      !isFetchingPublicRatingsData &&
      showEmptyState &&
      isEqual(tableData, LOADING_STATE_MOCK_RATINGS_DATA) ? (
        <EmptyState
          withContainer={false}
          icon={<Ratings className="w-5 h-5" />}
          title={UI_STRINGS.title}
          subtitle={UI_STRINGS.subtitle}
          functionalActionName={UI_STRINGS.buttonText}
          functionalActionOnClick={handleOnProjectCatalogClick}
        />
      ) : (
        <RatingsTable
          columns={formattedColumns}
          data={tableData}
          shouldLockRatings={handleShouldLockRatings}
        />
      )}
    </>
  )
}
