import type { FC } from 'react'
import clsx from 'clsx'
import { HoverTooltip } from '@sylveraio/react-ui'
import { ButtonLegacy } from '@sylveraio/design-system'
import type { TileFilterProps } from './types'

export const TileFilters: FC<TileFilterProps> = ({
  filters,
  onFilter,
  selected,
  testId,
  filtersTooltipMapping,
  filtersClassName,
  filterClassName,
  ignoreDisabled,
}) => {
  if (!filters || !onFilter) return null

  return (
    <ul className={clsx('flex z-10 gap-x-2 items-center', filtersClassName)}>
      {filters.map((filter) => (
        <li
          key={`filter-${testId}-${
            typeof filter === 'string' ? filter : filter.id
          }`}
          className={filterClassName}
        >
          <HoverTooltip
            content={
              filtersTooltipMapping &&
              filtersTooltipMapping[
                typeof filter === 'string' ? filter : filter.id
              ]
            }
            offset={[0, 10]}
            className="!max-w-[300px]"
          >
            <ButtonLegacy
              theme="forest"
              size="md"
              type="button"
              align="none"
              appearance={
                selected === (typeof filter === 'string' ? filter : filter.id)
                  ? 'solid'
                  : 'none'
              }
              disabled={
                !ignoreDisabled &&
                selected === (typeof filter === 'string' ? filter : filter.id)
              }
              shape="pill"
              className={clsx('!px-2', {
                '!text-default hover:bg-default hover:rounded-full':
                  selected !==
                  (typeof filter === 'string' ? filter : filter.id),
              })}
              onClick={() =>
                onFilter(typeof filter === 'string' ? filter : filter.id)
              }
            >
              {typeof filter === 'string' ? filter : filter.value}
            </ButtonLegacy>
          </HoverTooltip>
        </li>
      ))}
    </ul>
  )
}
