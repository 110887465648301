import { gql } from '@apollo/client'

export const POLICY_ARTICLE_THUMBNAIL_FRAGMENT = gql`
  fragment PolicyArticleThumbnailFragment on PolicyArticle {
    heroImage {
      title
      contentType
      url
      width
      height
    }
  }
`

export const POLICY_ARTICLE_FRAGMENT = gql`
  fragment PolicyArticleFragment on PolicyArticle {
    sys {
      id
    }
    articleTitle
    articleTitleSlug
    articleDate
    articleBody

    ...PolicyArticleThumbnailFragment

    sylverasTake {
      json
    }
    articleTagsCollection {
      items {
        ... on PolicyArticleTag {
          tagText
          tagTextId
          visibleInArticle
        }
        ... on CountryTag {
          tagText: country
          tagTextId: countryId
          visibleInArticle
        }
        ... on ProjectTypeTag {
          tagText: projectTypeText
          tagTextId: projectTypeId
          visibleInArticle
        }
      }
    }
    articleLinksCollection {
      items {
        url
        displayText
      }
    }
  }

  ${POLICY_ARTICLE_THUMBNAIL_FRAGMENT}
`

export const POLICY_ARTICLE_FRAGMENT_WITHOUT_THUMBNAIL = gql`
  fragment PolicyArticleFragmentWithoutThumbnail on PolicyArticle {
    sys {
      id
    }
    articleTitle
    articleTitleSlug
    articleDate
    articleBody
    sylverasTake {
      json
    }
    articleTagsCollection {
      items {
        ... on PolicyArticleTag {
          tagText
          tagTextId
          visibleInArticle
        }
        ... on CountryTag {
          tagText: country
          tagTextId: countryId
          visibleInArticle
        }
        ... on ProjectTypeTag {
          tagText: projectTypeText
          tagTextId: projectTypeId
          visibleInArticle
        }
      }
    }
    articleLinksCollection {
      items {
        url
        displayText
      }
    }
  }
`
