'use client'

import { FC, useMemo, useState } from 'react'
import clsx from 'clsx'
import { Table } from '@sylveraio/design-system'
import type { RatingsTableProps } from './types'
import { COLUMNS_STYLING } from './constants'
import { getAppUrl } from '@sylveraio/react-utils'

export const RatingsTable: FC<RatingsTableProps> = ({
  testId,
  data,
  columns,
  loading,
  shouldLockRatings,
}) => {
  const [selectedRow, setSelectedRow] = useState<string | undefined>()

  const tableData = useMemo(
    () =>
      data?.map(({ projectId, projectStage, ...rest }) => ({
        ...rest,
        _id: projectId,
        _lockProject: shouldLockRatings?.(projectStage, projectId),
      })),
    [data],
  )

  const handleSelectRow = (
    value: string,
    rowData?: Record<string, unknown>,
  ) => {
    if (!value || rowData?._lockProject) return

    setSelectedRow(value)

    window.open(`${getAppUrl('ratings')}/project/${value}`, '_blank')
  }

  return (
    <Table
      testId={testId}
      columns={columns}
      dataset={tableData}
      headerClassName="h-[32px] !border-strong !text-subtle"
      rowClassName={clsx(
        'h-[70px] border-b-[0.5px] border-default last:!border-b-0 hover:bg-medium',
      )}
      className="z-10 text-default"
      tableClassName="desktop-md:table-fixed"
      loading={loading}
      columnClassName={{ ...COLUMNS_STYLING }}
      specificRowClassName={[
        {
          key: '_lockProject',
          className: '!cursor-default',
        },
      ]}
      selectedRow={{
        key: '_id',
        // @ts-ignore
        value: selectedRow,
        onClick: handleSelectRow,
        className: '',
      }}
    />
  )
}
