import type { FC } from 'react'
import { Pill } from '@sylveraio/react-ui'
import { RECENTLY_RATED_TEXT } from './constants'
import { EarlyStageProjectsPill } from '../../components/EarlyStageProjectsPill'
import { RatingsTablePermissioned } from '../RatingsTablePermissioned'

export const RatingsTile: FC = () => {
  return (
    <>
      <div className="flex gap-x-2 py-4">
        <Pill
          color="none"
          selectedVariation="dark"
          name="Recent"
          selected
          className="w-min !px-2 !text-xs !font-normal"
          readOnly
        >
          {RECENTLY_RATED_TEXT}
        </Pill>
        <EarlyStageProjectsPill />
      </div>
      <RatingsTablePermissioned />
    </>
  )
}
