import { useState, FC } from 'react'
import {
  EarlyStageProjectsModal,
  EarlyStageProjectsModalStep,
} from '@sylveraio/react-ui'
import { useUser } from '@sylveraio/data'
import { submitEarlyStageCatalogForm } from '@sylveraio/auth-utils'
import { Label, Typography } from '@sylveraio/design-system'
import Lock01 from '@sylveraio/untitled-ui-icons-react/build/esm/Lock01'
import { useFlags } from 'launchdarkly-react-client-sdk'

const INITIAL_STEP: EarlyStageProjectsModalStep =
  EarlyStageProjectsModalStep.Form

export const EarlyStageProjectsPill: FC = () => {
  const {
    showEarlyStageProjectsTab,
    showEarlyStageProjectsTabNewLabel,
    hasEarlyStageProjectsAccess,
  } = useFlags()
  const { data: userData } = useUser()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [step, setStep] = useState<EarlyStageProjectsModalStep>(INITIAL_STEP)
  const [isModalOpen, setIsModalOpen] = useState(false)

  if (!userData || !showEarlyStageProjectsTab) {
    return null
  }

  async function handleSubmit() {
    if (!userData) {
      return
    }

    setIsSubmitting(true)
    try {
      await submitEarlyStageCatalogForm(userData)
      setStep(EarlyStageProjectsModalStep.Submitted)
    } finally {
      setIsSubmitting(false)
    }
  }

  function handleClose() {
    setIsModalOpen(false)
    setStep(INITIAL_STEP)
  }

  return (
    <>
      <button
        onClick={() => setIsModalOpen(true)}
        role="button"
        className="flex items-center gap-x-1.5 rounded-full px-2 py-0.5 hover:bg-high-hover"
      >
        {showEarlyStageProjectsTabNewLabel && (
          <Label label="New" color="discovery" strong size="sm" ping />
        )}
        <Typography size="xs" weight="regular">
          Early-stage
        </Typography>

        {!hasEarlyStageProjectsAccess && <Lock01 className="h-4 w-4" />}
      </button>

      {isModalOpen && (
        <EarlyStageProjectsModal
          hasAccessToEarlyStageProjects={hasEarlyStageProjectsAccess}
          modalStep={step}
          isSubmitting={isSubmitting}
          onClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}
    </>
  )
}
