import { type Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import React from 'react'

export const REVALIDATION_SECONDS = 10 * 60 // 10 minutes

export const NEXTJS_FETCH_OPTIONS = {
  fetchOptions: {
    next: { revalidate: REVALIDATION_SECONDS },
  },
}

/**
 * Contenful Rich text transformer options
 */
export const options: Options = {
  renderMark: {
    [MARKS.BOLD]: (text) => (
      <span className="font-bold text-subtle">{text}</span>
    ),
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, _children) => {
      let children = _children

      if (Array.isArray(children)) {
        children = children.filter(Boolean)
      }

      return (
        <span className="text-subtle mb-6 block last:mb-0">{children}</span>
      )
    },
    [BLOCKS.HEADING_5]: (_, children) => {
      return <span className="text-base font-medium leading-6">{children}</span>
    },
    [BLOCKS.HEADING_6]: (_, children) => {
      return (
        <span className="text-xs text-subtle font-light line-clamp-none leading-normal">
          {children}
        </span>
      )
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const uri = node.data.uri
      return (
        <a
          className="cursor-pointer underline hover:no-underline"
          href={uri.startsWith('https://') ? uri : `https://${uri}`}
          target="_blank"
        >
          {children}
        </a>
      )
    },
    [BLOCKS.LIST_ITEM]: (_, children) => {
      // Remove 'block' property for lists
      const filteredChildren = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          const props = {
            ...child.props,
            className: child.props.className?.replace('block', ''),
          }
          return React.cloneElement(child, props)
        }
        return child
      })
      return <li className="list-inside list-disc">{filteredChildren}</li>
    },
    [BLOCKS.UL_LIST]: (_, children) => {
      return <ul className="list-inside list-disc">{children}</ul>
    },
    [BLOCKS.OL_LIST]: (_, children) => {
      return <ol className="list-inside list-decimal">{children}</ol>
    },
  },
}

export const COMPARISON_PAGE_RICH_TEXT_OPTIONS: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, _children) => {
      let children = _children

      if (Array.isArray(children)) {
        children = children.filter(Boolean)
      }

      return <span className="mb-3 block last:mb-0">{children}</span>
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const uri = node.data.uri
      return (
        <a
          className="cursor-pointer underline hover:no-underline"
          href={uri.startsWith('https://') ? uri : `https://${uri}`}
          target="_blank"
        >
          {children}
        </a>
      )
    },
  },
}

export const RISK_LEVELS = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
] as const
