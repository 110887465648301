import type { AppName } from 'types'
import { RatingsTile } from '../containers/RatingsTile'

export const MARKET_COMMENTARY_REVALIDATION_SECONDS = 60 * 5
export const BUCKET_NAME_ASSETS = `assets.${process.env['NEXT_PUBLIC_APP_ENV']}.sylvera.com`
export const BUCKET_NAME_CMS_DATA = `sylvera-${process.env['NEXT_PUBLIC_APP_ENV']}-publicweb-cms-data-dump`

/**
 * Tile components that will replace images can be mapped here for each component
 */
export const TILE_CONTAINER_COMPONENT_MAP: Partial<{
  [x in AppName]: JSX.Element
}> = {
  ratings: <RatingsTile />,
}

export const TILE_TOOLTIP_MSG_MAP: Partial<{
  [x in AppName]: string
}> = {
  analytics:
    'Sylvera’s Carbon Credit Analytics helps new and experienced market participants analyze, benchmark and compare the quality of public and non-public retirements across more than 8 registries/standards, and match over 514 million retired credits to 1500+ companies.',
  ratings:
    'Sylvera’s Project Catalog offers a comprehensive database of carbon projects, along with Ratings and Screening assessments to identify key positives and risks associated with projects.',
  insights:
    'Sylvera’s Insights bring together our market knowledge, reports and framework whitepapers together in one place for you to read and download.',
  box: 'Jurisdictional Intel supports new and experienced market participants in understanding and navigating the complex Jurisdictional REDD+ (JREDD+) landscape to leverage opportunities and tackle risks arising from this soaring project type.',
  'market-commentary':
    'Discover key market developments and read Sylvera’s take to inform your carbon strategy and execution.',
  'country-profiles':
    'Inform your carbon strategy: discover new opportunities, explore supply by jurisdiction, mitigate country-specific risks, and stay ahead of policy changes.',
}

export const EMPTY_CONTENT = '-'
export const NA_CONTENT = 'N/A'

export const ALL_ARTICLES_LABEL = 'All articles'

export const USER_GUIDE = 'User guide'
