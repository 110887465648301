import clsx from 'clsx'
import { useMemo, type FC } from 'react'
import { Badge } from '@sylveraio/react-ui'
import type { SylveraRatingsProps } from './types'
import { RING_SIZES, TEXT_SIZES, BADGE_LOCATIONS } from './constants'

export const SylveraRating: FC<SylveraRatingsProps> = ({
  testId = 'sylvera-rating',
  rating,
  size = 'sm',
  isProvisional,
}) => {
  const showNA = useMemo(() => !rating, [rating])

  return (
    <div
      data-testid={testId}
      className={clsx(
        'relative rounded-full bg-default flex items-center justify-center',
        { [`${RING_SIZES?.outer?.[size]}`]: RING_SIZES?.outer?.[size] },
      )}
    >
      {isProvisional && !showNA && (
        <Badge
          className={clsx(
            'bg-content-action-default-hover text-default-on-dark flex items-center justify-center rounded-full absolute !p-0 z-20 h-4 w-4 !text-xs font-semibold',
            { [`${BADGE_LOCATIONS?.[size]}`]: BADGE_LOCATIONS?.[size] },
          )}
        >
          P
        </Badge>
      )}
      <div className="absolute left-0 top-0 h-full w-full rounded-full shadow-rating" />
      <div
        className={clsx(
          'relative z-10 rounded-full flex items-center justify-center',
          {
            'bg-progress-ring-gradient': !showNA,
            '!bg-[#B8B8B8] !bg-none': showNA,
          },
          { [`${RING_SIZES?.middle?.[size]}`]: RING_SIZES?.middle?.[size] },
        )}
      >
        <div
          className={clsx(
            'rounded-full bg-high flex items-center justify-center',
            { [`${RING_SIZES?.inner?.[size]}`]: RING_SIZES?.inner?.[size] },
          )}
        >
          <span
            data-testid="ring-label"
            className={clsx('font-bold text-content-action-dark', {
              [`${TEXT_SIZES?.[size]}`]: TEXT_SIZES?.[size],
            })}
          >
            {showNA ? 'NA' : rating}
          </span>
        </div>
      </div>
    </div>
  )
}
