import type {
  TransformedRatedProject,
  TransformedRatedProjects,
} from '@sylveraio/data'

export const RATINGS_TILE_DATA_SORT = '-dateAdded'
export const RATINGS_TILE_DATA_PAGE_SIZE = 6
export const RATINGS_TILE_DATA_PUBLIC_FIELDS = [
  'name',
  'countryName',
  'categories',
  'onWatch',
  'registryId',
  'registryName',
  'issuance',
]
export const RATINGS_TILE_DATA_PRIVATE_FIELDS = ['analysis']

const LOADING_STATE_MOCK_RATING_DATA: TransformedRatedProject = {
  projectId: '',
  projectName: '',
  registryId: '',
  // @ts-ignore
  registryName: '',
  onWatch: false,
  // @ts-ignore
  projectType: '',
  projectCountry: '',
}

export const LOADING_STATE_MOCK_RATINGS_DATA: TransformedRatedProjects =
  Array.from(
    { length: RATINGS_TILE_DATA_PAGE_SIZE },
    () => LOADING_STATE_MOCK_RATING_DATA,
  )

export const UI_STRINGS = {
  buttonText: 'Open Project Catalog',
  title: "We haven't rated any projects in this country yet",
  subtitle:
    "Browse our Project Catalog and request an assesment for specific projects you're interested in.",
}
