import type { SelectedPolicyFilters } from '@sylveraio/data'

/**
 * Checks if all policy filters are either undefined or empty arrays.
 *
 * @param {SelectedPolicyFilters} policyFilters - An object containing the policy filters.
 * @returns {boolean} True if all filters are undefined or empty arrays, false otherwise.
 */
export const isPolicyFiltersEmpty = (
  policyFilters: SelectedPolicyFilters | undefined,
): boolean => {
  if (typeof policyFilters === 'undefined') return true

  return !Object.values(policyFilters).some(
    (value) => Array.isArray(value) && value.length > 0,
  )
}
