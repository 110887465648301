import { ButtonLegacy, Typography } from '@sylveraio/design-system'
import { FC } from 'react'
import { CustomiseModalButtonProps } from './types'
import { CUSTOMIZE_YOUR_FEED_LABEL } from './constants'

export const CustomiseModalButton: FC<CustomiseModalButtonProps> = ({
  testId = 'customise-modal-button',
  onClick,
}) => (
  <ButtonLegacy
    testId={testId}
    appearance="none"
    align="none"
    iconSuffix={{
      icon: 'cog',
      size: 'sm',
      // Temporary will be changed to use icon semantic colours when new
      // icons have been implemented as current icons do not all support
      // stroke- prefix
      className: '!text-subtle-hover',
    }}
    onClick={onClick}
  >
    <Typography size="xs" weight="medium" className="!text-subtle">
      {CUSTOMIZE_YOUR_FEED_LABEL}
    </Typography>
  </ButtonLegacy>
)
