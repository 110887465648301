import { gql } from '@apollo/client'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'
import type {
  CountryTag,
  PolicyArticleEntry,
  PolicyArticleTag,
  ProjectTypeTag,
  TagFilterSysIds,
} from '../types'
import { POLICY_ARTICLE_FRAGMENT } from './fragments'

export const GET_ARTICLE: TypedDocumentNode<
  {
    policyArticleCollection: { items: Array<PolicyArticleEntry> }
  },
  { slug: string }
> = gql`
  query GetArticle($slug: String) {
    policyArticleCollection(limit: 1, where: { articleTitleSlug: $slug }) {
      items {
        ...PolicyArticleFragment
      }
    }
  }

  ${POLICY_ARTICLE_FRAGMENT}
`

export const GET_ALL_ARTICLES: TypedDocumentNode<{
  policyArticleCollection: { items: Array<PolicyArticleEntry> }
  policyArticleTagCollection: { items: Array<PolicyArticleTag> }
  countryTagCollection: { items: Array<CountryTag> }
  projectTypeTagCollection: { items: Array<ProjectTypeTag> }
}> = gql`
  query GetAllArticles {
    policyArticleCollection(limit: 10, order: articleDate_DESC) {
      items {
        ...PolicyArticleFragment
      }
    }
    policyArticleTagCollection(where: { filterable: true }) {
      items {
        tagTextId
        tagText
        visibleInArticle
        filterable
      }
    }
    countryTagCollection {
      items {
        country
        countryId
      }
    }
    projectTypeTagCollection {
      items {
        projectTypeId
        projectTypeText
      }
    }
  }

  ${POLICY_ARTICLE_FRAGMENT}
`

export const GET_LATEST_ARTICLES: TypedDocumentNode<
  {
    policyArticleCollection: { items: Array<PolicyArticleEntry> }
  },
  { ids?: Array<string>; limit: number }
> = gql`
  query GetLatestArticles($ids: [String], $limit: Int) {
    policyArticleCollection(
      limit: $limit
      where: { sys: { id_in: $ids } }
      order: articleDate_DESC
    ) {
      items {
        ...PolicyArticleFragment
      }
    }
  }

  ${POLICY_ARTICLE_FRAGMENT}
`

export const GET_PAGINATED_LATEST_ARTICLES: TypedDocumentNode<
  {
    policyArticleCollection: { items: Array<PolicyArticleEntry>; total: number }
  },
  { searchQuery?: string; ids?: Array<string>; limit: number; skip: number }
> = gql`
  query PaginatedGetLatestArticles(
    $searchQuery: String
    $ids: [String]
    $limit: Int
    $skip: Int
  ) {
    policyArticleCollection(
      limit: $limit
      where: {
        AND: [
          {
            OR: [
              { articleTitle_contains: $searchQuery }
              { articleBody_contains: $searchQuery }
              { sylverasTake_contains: $searchQuery }
            ]
          }
          { sys: { id_in: $ids } }
        ]
      }
      order: articleDate_DESC
      skip: $skip
    ) {
      items {
        ...PolicyArticleFragment
      }
      total
    }
  }

  ${POLICY_ARTICLE_FRAGMENT}
`

export const GET_ARTICLE_IDS: TypedDocumentNode<
  {
    policyArticleTagCollection: TagFilterSysIds
    countryTagCollection: TagFilterSysIds
    projectTypeTagCollection: TagFilterSysIds
  },
  {
    tags: Array<string>
    countries: Array<string>
    projectTypes: Array<string>
  }
> = gql`
  query GetArticleIds(
    $tags: [String]
    $countries: [String]
    $projectTypes: [String]
  ) {
    policyArticleTagCollection(where: { tagTextId_in: $tags }) {
      items {
        linkedFrom {
          policyArticleCollection(limit: 50) {
            items {
              sys {
                id
              }
            }
          }
        }
      }
    }
    countryTagCollection(where: { countryId_in: $countries }) {
      items {
        linkedFrom {
          policyArticleCollection(limit: 25) {
            items {
              sys {
                id
              }
            }
          }
        }
      }
    }
    projectTypeTagCollection(where: { projectTypeId_in: $projectTypes }) {
      items {
        linkedFrom {
          policyArticleCollection(limit: 25) {
            items {
              sys {
                id
              }
            }
          }
        }
      }
    }
  }
`
