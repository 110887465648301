import { FC, useCallback, useContext, useMemo } from 'react'
import type { Row } from '@tanstack/react-table'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { OnWatchLabel, ProgressRingLockButton } from '@sylveraio/react-ui'
import { Typography, type TableColumn } from '@sylveraio/design-system'
import { HubspotStateContext } from '@sylveraio/auth'
import { TransformedRatedProject, useUser } from '@sylveraio/data'
import { SylveraRating } from '../../../components/SylveraRating'
import { PROVISIONAL_RATING_SHORTNAMES } from '../../../components/SylveraRating/constants'
import { RatingsTableProject } from '../../../components/RatingsTable/types'

const Title: FC<{ title: string }> = ({ title }) => (
  <Typography size="xs" weight="regular" className="!text-subtle">
    {title}
  </Typography>
)

export function useRatingsTableColumns(
  hasRatingsAccess?: boolean,
  isLoadingPublicData?: boolean,
  isLoadingPrivateData?: boolean,
  hideColumns: Array<
    Extract<keyof TransformedRatedProject, 'projectCountry' | 'estimatedSupply'>
  > = ['estimatedSupply'],
  disableRequestProject?: boolean,
): TableColumn<RatingsTableProject>[] {
  const { data } = useUser()
  const { setHubspotFormCustom, setHubspotAppName } =
    useContext(HubspotStateContext)

  const handleOnLockedClick = useCallback(
    (projectName?: string, projectId?: string) => {
      if (!hasRatingsAccess) {
        setHubspotAppName('ratings')
        return
      }

      setHubspotFormCustom({
        variant: 'project',
        data: {
          userOrganisation: data?.organisation,
          userFirstName: data?.givenName,
          userLastName: data?.familyName,
          userId: data?.userId,
          userEmail: data?.email,
          projectName,
          projectId,
        },
      })
    },
    [hasRatingsAccess, data],
  )

  const defaultColumns: TableColumn<RatingsTableProject>[] = useMemo(
    () => [
      {
        datasetKey: '_id',
        title: 'id',
      },
      {
        datasetKey: '_lockProject',
        title: '_lockProject',
        hideColumn: true,
      },
      {
        datasetKey: 'sylveraRating',
        header: () => <Title title="Rating" />,
        Cell: ({ row }: { row: Row<RatingsTableProject> }) => {
          if (isLoadingPublicData)
            return <Skeleton circle width={48} height={48} />
          else if (row?.original?._lockProject)
            return (
              <ProgressRingLockButton
                disableClick={disableRequestProject}
                onClick={() =>
                  !disableRequestProject &&
                  handleOnLockedClick(
                    row?.original?.projectName,
                    row?.original?._id,
                  )
                }
              />
            )

          return (
            <>
              {isLoadingPrivateData ? (
                <Skeleton circle width={48} height={48} />
              ) : (
                <SylveraRating
                  rating={
                    row?.original?.sylveraRatingProvisional
                      ? PROVISIONAL_RATING_SHORTNAMES[
                          row.original.sylveraRatingProvisional
                        ]
                      : row?.original?.sylveraRating
                  }
                  isProvisional={Boolean(
                    row?.original?.sylveraRatingIsProvisional,
                  )}
                />
              )}
            </>
          )
        },
      },
      {
        datasetKey: 'projectName',
        header: () => <Title title="Name/Registry/ID" />,
        Cell: ({ row }) => {
          return (
            <>
              {isLoadingPublicData ? (
                <Skeleton count={2} width="80%" />
              ) : (
                <div className="flex flex-col gap-0.5">
                  <OnWatchLabel show={row?.original?.onWatch} />
                  <Typography size="sm" weight="semibold" className="uppercase">
                    {row?.original?.projectName}
                  </Typography>
                  {row?.original?.registryName && row?.original?.registryId && (
                    <Typography
                      size="xs"
                      weight="regular"
                      className="!text-subtle"
                    >
                      {row.original.registryName}, {row.original.registryId}
                    </Typography>
                  )}
                </div>
              )}
            </>
          )
        },
      },
      {
        datasetKey: 'projectType',
        header: () => <Title title="Type" />,
        Cell: ({ row }) => {
          return (
            <>
              {isLoadingPublicData ? (
                <Skeleton />
              ) : (
                <Typography size="xs" weight="regular" className="!text-subtle">
                  {row?.original?.projectType}
                </Typography>
              )}
            </>
          )
        },
      },
      {
        datasetKey: 'projectCountry',
        header: () => <Title title="Country" />,
        Cell: ({ row }) => {
          return (
            <>
              {isLoadingPublicData ? (
                <Skeleton />
              ) : (
                <Typography size="xs" weight="regular" className="!text-subtle">
                  {row?.original?.projectCountry}
                </Typography>
              )}
            </>
          )
        },
        hideColumn: hideColumns?.includes('projectCountry'),
      },
      {
        datasetKey: 'estimatedSupply',
        header: () => <Title title="Est. supply" />,
        Cell: ({ row }) => {
          return (
            <>
              {isLoadingPublicData ? (
                <Skeleton />
              ) : (
                <Typography size="xs" weight="regular" className="!text-subtle">
                  {row?.original?.estimatedSupply === undefined
                    ? '-'
                    : row?.original?.estimatedSupply?.toLocaleString()}
                </Typography>
              )}
            </>
          )
        },
        hideColumn: hideColumns?.includes('estimatedSupply'),
      },
    ],
    [hasRatingsAccess, isLoadingPublicData, isLoadingPrivateData],
  )

  return defaultColumns
}

export default useRatingsTableColumns
