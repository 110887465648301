import { useMemo } from 'react'
import { PolicyArticleEntry } from '../../../containers/PolicyArticle/types'
import { PolicyArticleEntry as PolicyArticleEntryProps } from '../../../components/PolicyArticle/types'

export function useTransformArticle(
  policyArticle?: PolicyArticleEntry,
): PolicyArticleEntryProps | null {
  return useMemo(() => {
    if (!policyArticle) return null

    const imgSrc = policyArticle?.heroImage?.url
    const imgAlt = policyArticle?.heroImage?.title
    const imgWidth = policyArticle?.heroImage?.width
    const imgHeight = policyArticle?.heroImage?.height
    const externalLink = policyArticle?.articleLinksCollection.items
    const sylverasTake = policyArticle?.sylverasTake

    return {
      id: policyArticle?.sys?.id,
      date: policyArticle?.articleDate,
      img: { src: imgSrc, alt: imgAlt, width: imgWidth, height: imgHeight },
      summary: policyArticle?.articleBody,
      tags: policyArticle?.articleTagsCollection?.items
        .filter((tag) => tag?.visibleInArticle)
        .map((tag) => tag?.tagText),
      title: policyArticle?.articleTitle,
      externalLink,
      sylverasTake,
    }
  }, [policyArticle])
}
