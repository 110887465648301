import { Typography } from '@sylveraio/design-system'
import { FC } from 'react'
import type { Document } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import clsx from 'clsx'

export const PolicyArticleSylverasTake: FC<{
  sylverasTake: string | Document
  clampLines?: boolean
}> = ({ sylverasTake, clampLines = false }) =>
  sylverasTake ? (
    <div className="relative">
      <Typography size="xs" className="absolute top-0 left-0 !text-subtle">
        Sylvera&apos;s take:
      </Typography>
      <div
        className={clsx('top-0 left-0 absolute indent-20', {
          'line-clamp-3': clampLines,
        })}
      >
        {typeof sylverasTake === 'string'
          ? sylverasTake
          : documentToReactComponents(sylverasTake)}
      </div>
    </div>
  ) : null
