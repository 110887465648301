import { TagFilterSysIds } from '../../containers/PolicyArticle/types'

/**
 * Reduces the input data structure containing collections of tags and their associated policy articles
 * to a flat array of article system IDs.
 *
 * @param {TagFilterSysIds} data - The data object containing collections of tags, each linking to policy articles.
 * @returns {Array<string>} An array of unique system IDs for all articles linked from the input tag collections.
 */
export function reduceTagFilters(data: TagFilterSysIds) {
  return data.items.reduce((prev, curr) => {
    const ids = curr.linkedFrom.policyArticleCollection.items.map(
      ({ sys }) => sys.id,
    )
    return [...prev, ...ids]
  }, [] as Array<string>)
}
