export const COLUMNS_STYLING = {
  sylveraRating: {
    th: {
      outer: 'w-[68px]',
      inner: '!text-xs',
    },
    td: {
      outer: '!pl-0 !px-1',
    },
  },
  projectName: {
    th: {
      inner: '!text-xs',
    },
    td: {
      outer: '!px-1 ',
    },
  },
  projectType: {
    th: {
      outer: 'w-[87px]',
      inner: '!text-xs',
    },
    td: {
      outer: '!px-1',
    },
  },
  projectCountry: {
    th: {
      outer: 'w-[74px]',
      inner: '!text-xs',
    },
    td: {
      outer: '!pr-0 !px-1',
    },
  },
  estimatedSupply: {
    th: {
      outer: 'w-[96px]',
      inner: '!text-xs',
    },
    td: {
      outer: '!pr-0 !px-1',
    },
  },
}
