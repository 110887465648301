import { useMemo } from 'react'
import { PolicyArticleEntry } from '../../../containers/PolicyArticle/types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { usePathname } from 'next/navigation'
import type { UseTransformArticlesReturn } from './types'
import { options } from '../../../constants/server'

/**
 * Transforms policy article entries for the News List Item component
 *
 * @param {PolicyArticleEntry[]} [policyArticles] - An optional array of policy article entries to transform.
 * @returns {UseTransformArticlesReturn[] | null} An array of transformed articles with added properties for rendering or null if no articles are provided.
 */
export function useTransformArticles(
  policyArticles?: PolicyArticleEntry[] | null,
): UseTransformArticlesReturn[] | null {
  const pathname = usePathname()

  return useMemo(
    () =>
      policyArticles
        ? policyArticles?.map((article) => ({
            id: article?.sys?.id,
            date: article?.articleDate,
            img: {
              src: article?.heroImage?.url,
              alt: article?.heroImage?.title,
              width: article?.heroImage?.width,
              height: article?.heroImage?.height,
            },
            summary: documentToReactComponents(
              article?.sylverasTake?.json,
              options,
            ),
            tags: article?.articleTagsCollection?.items
              .filter((tag) => tag?.visibleInArticle)
              .map((tag) => tag?.tagText),
            title: article?.articleTitle,
            linkTo: pathname
              ? `${pathname}?policyArticle=${article?.articleTitleSlug}`
              : '',
          }))
        : null,
    [policyArticles, pathname],
  )
}
