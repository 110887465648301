'use client'

import { useSuspenseQuery, skipToken } from '@apollo/client'
import { useEffect, useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import intersection from 'lodash/intersection'
import {
  GET_ARTICLE_IDS,
  GET_PAGINATED_LATEST_ARTICLES,
} from '../../../containers/PolicyArticle/queries'
import type { SelectedPolicyFilters } from '@sylveraio/data'
import { reduceTagFilters } from '../../reduceTagFilters'

/**
 * Fetches filtered articles from the Contentful GraphQL API with pagination support.
 * The hook allows for dynamic filtering based on selected tags, countries, and project types.
 * If specified filters result in no matching IDs, or if the operation is bypassed, no data is fetched.
 * Pagination parameters (limit and page) control the number of items fetched and their offset.
 *
 * @param {SelectedPolicyFilters} [selectedFilters] Filters selected by the user. Includes tags, countries, and project types.
 * @param {number} [limit=10] The number of articles to fetch per page.
 * @param {number} [page=1] The current page number for pagination.
 * @param {boolean} [bypass=false] If true, skips fetching filtered articles.
 * @returns {Object} An object containing two properties: `filteredArticles` (the current page of articles)
 *                   and `filteredTotalArticles` (the total number of articles across all pages).
 */
export function usePaginatedFilteredArticles(
  searchQuery?: string,
  selectedFilters?: SelectedPolicyFilters,
  limit = 10,
  page = 1,
  bypass = false,
) {
  const [filteredArticleIds, setFilteredArticleIds] = useState<Array<string>>(
    [],
  )
  const shouldFetch = useMemo(
    () =>
      Object.values(selectedFilters || {}).filter((v) => !isEmpty(v)).length >
      0,
    [selectedFilters],
  )

  const { data: ids } = useSuspenseQuery(
    GET_ARTICLE_IDS,
    bypass || !shouldFetch
      ? skipToken
      : {
          variables: {
            tags: selectedFilters?.policyTags || [],
            countries: selectedFilters?.countries || [],
            projectTypes: selectedFilters?.projectTypes || [],
          },
          fetchPolicy: 'cache-and-network',
        },
  )

  useEffect(() => {
    if (!ids) return setFilteredArticleIds([])
    const filters = {
      tagIds:
        (selectedFilters?.policyTags?.length || 0) > 0
          ? reduceTagFilters(ids.policyArticleTagCollection)
          : undefined,
      countryIds:
        (selectedFilters?.countries?.length || 0) > 0
          ? reduceTagFilters(ids.countryTagCollection)
          : undefined,
      projectTypeIds:
        (selectedFilters?.projectTypes?.length || 0) > 0
          ? reduceTagFilters(ids.projectTypeTagCollection)
          : undefined,
    }

    const withData = Object.entries(filters)
      .filter(([, v]) => typeof v !== 'undefined')
      .map(([, v]) => v)

    const policyIds = intersection(...withData)

    setFilteredArticleIds(policyIds)
  }, [ids])

  const offset = (page - 1) * limit

  const { data } = useSuspenseQuery(
    GET_PAGINATED_LATEST_ARTICLES,
    bypass
      ? skipToken
      : {
          variables: {
            searchQuery,
            ids:
              filteredArticleIds.length === 0 && typeof ids !== 'undefined'
                ? []
                : filteredArticleIds.length
                ? filteredArticleIds
                : undefined,
            limit,
            skip: offset,
          },
          fetchPolicy: 'cache-and-network',
        },
  )

  return {
    filteredArticles: data?.policyArticleCollection?.items || [],
    filteredTotalArticles: data?.policyArticleCollection?.total,
  }
}
