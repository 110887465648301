import { useQuery } from '@apollo/client'
import { GET_ARTICLE } from '../../../containers/PolicyArticle/queries'
import { useTransformArticle } from '../useTransformArticle'
import { useMemo } from 'react'

/**
 * Custom hook to fetch and transform article data by its slug.
 *
 * @param {string} slug - The unique slug for the article to be fetched.
 * @param {boolean} [skip=false] - Optional flag to skip the query execution.
 * @returns {object} An object containing the transformed article, tags, and any error if occurred.
 *
 * @example
 * const { article, tags, error } = useArticle('slug');
 */
export function useArticle(slug: string, skip?: boolean) {
  const { data, error } = useQuery(GET_ARTICLE, {
    variables: {
      slug,
    },
    skip,
    nextFetchPolicy: 'cache-and-network',
    fetchPolicy: 'cache-and-network',
    initialFetchPolicy: 'cache-and-network',
  })

  const policyArticle = data?.policyArticleCollection?.items?.[0]

  const transformedArticle = useTransformArticle(policyArticle)

  const transformedTags = useMemo(() => {
    return transformedArticle?.tags.map((tag) => ({
      name: tag,
      value: tag,
      children: tag.toUpperCase(),
      readOnly: true,
    }))
  }, [transformedArticle])

  return {
    article: transformedArticle,
    tags: transformedTags,
    error,
  }
}
